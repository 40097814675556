import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/Mainpage";
import Consulting_article from "./components/Consulting_article";
import Consulting_course from "./components/Consulting_course";
function App() {
  return (
      <></>
  );
}

export default App;
